* {
  box-sizing: border-box;
}

html, body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

.col-p-1  { width: 8.33%;  }
.col-p-2  { width: 16.66%; }
.col-p-3  { width: 25%;    }
.col-p-4  { width: 33.33%; }
.col-p-5  { width: 41.66%; }
.col-p-6  { width: 50%;    }
.col-p-7  { width: 58.33%; }
.col-p-8  { width: 66.66%; }
.col-p-9  { width: 75%;    }
.col-p-10 { width: 83.33%; }
.col-p-11 { width: 91.66%; }
.col-p-12 { width: 100%;   }

.row::after {
  content: "";
  clear: both;
  display: table;
  height: 30%;
}

.parent {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.board {
  height: 70%;
  border: 5px solid black;
  container: board-container / height;
  max-height: 1143px;
  overflow: hidden;
}

.square {
  background: #fff;
  position: relative;
  border: 1px solid #999;
  height: 100%;
  margin-right: -1px;
  max-height: calc(1143px / 3);
  margin-top: -1px;
  text-align: center;
  justify-content: center;
  align-content: center;
  display: inline-block;
}

.square-text {
  font-size: 20vh;
  font-weight: bold;
  line-height: 100%;
}

.board-row {
  display: flex;
  width: 100%;
  height: 33.33%;
}

.status {
  background-color: grey;
  height: 30%;
  display: table;
  align-items: center;
  justify-content: center;
}

.status-text {
  font-size: 600%;
  font-weight: bold;
  line-height: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.reset-button {
  height: 20%;
  font-size: 200%;
}

@media only screen and (min-width: 700px) {
  /* Larger Screen Layout (Tablet/ Desktop) */
  .col-w-1  { width: 8.33%;  }
  .col-w-2  { width: 16.66%; }
  .col-w-3  { width: 25%;    }
  .col-w-4  { width: 33.33%; }
  .col-w-5  { width: 41.66%; }
  .col-w-6  { width: 50%;    }
  .col-w-7  { width: 58.33%; }
  .col-w-8  { width: 66.66%; }
  .col-w-9  { width: 75%;    }
  .col-w-10 { width: 83.33%; }
  .col-w-11 { width: 91.66%; }
  .col-w-12 { width: 100%;   }
  [class*="col-"] {
    float: left;
  }
  .board {
    overflow: initial;
  }

  .parent {
    display: flex; 
  }
}
